<template>
  <div>
    <v-dialog v-model="showWindow" max-width="900">
      <template v-slot:default="dialog">
        <v-card tile class="membership-fee-window">
          <v-toolbar color="primary" dark>
            <span v-if="member">Fee report of {{ member.name }}</span>
          </v-toolbar>
          <v-card-text>
            <div v-if="member" class="pt-4">
              <v-row no-gutters class="px-1">
                <v-col cols="12" sm="2" class="px-0">
                  <v-select :items="years" v-model="selectedYear" label="Select year"></v-select>
                </v-col>
                <v-col cols="12" sm="10" class="p-0 pr-0 pt-2 pb-4 text-right membership-button-wrap">
                  Membership class: {{ member.membershipPlan.name }}, membership
                  class fee ₹{{ member.membershipPlan.amount }}
                  {{ member.isMemberShipPlanFeesPaid ? "paid" : "not paid" }}
                  <v-btn @click="showPlanWindow = true" x-small tile class="primary ml-3">Membership Fee</v-btn>
                </v-col>
              </v-row>

              <v-row no-gutters v-if="!isLoading" class="fee-months-parent">
                <v-col class="fee-month text-center px-1" sm="3" xs="3" md="3" lg="2" cols="12"
                  v-for="(n, monthIndex) in 12" :key="monthIndex">
                  <v-card :disabled="isBeforeJoin(selectedYear, monthIndex)" min-height="75" :class="[
                    feeDataComputed[monthIndex] ? 'paid' : '',
                    isPending(selectedYear, monthIndex) &&
                      member.membershipPlan.monthlyAmount === 0
                      ? 'paid'
                      : '',
                    !feeDataComputed[monthIndex] &&
                      feeDataComputed[monthIndex] !== false &&
                      isPending(selectedYear, monthIndex) &&
                      member.membershipPlan.monthlyAmount !== 0
                      ? 'pending'
                      : '',
                    feeDataComputed[monthIndex] &&
                      isAdvance(selectedYear, monthIndex)
                      ? 'advance'
                      : '',
                    'px-1 mb-2 each-month-box d-flex align-center'
                  ]">
                    <div style="width: 100%">
                      <v-btn @click="showDeleteFeeDialog(selectedYear, monthIndex)" v-if="feeDataComputed[monthIndex] !== false &&
                        feeDataComputed[monthIndex] &&
                        member.membershipPlan.monthlyAmount != 0
                        " class="fee-check-button p-0" small>
                        <v-icon color="white">mdi-delete</v-icon>
                      </v-btn>
                      <v-btn @click="showPayUpToFeeDialog(selectedYear, monthIndex)" v-if="feeDataComputed[monthIndex] !== false &&
                        !feeDataComputed[monthIndex] &&
                        !isBeforeJoin(selectedYear, monthIndex) &&
                        member.membershipPlan.monthlyAmount != 0
                        " class="fee-check-button p-0" small>
                        <v-icon color="success">mdi-cash-check</v-icon>
                      </v-btn>
                      <div class="fee-month">{{ monthName(n) }}</div>
                      <div v-if="feeDataComputed[monthIndex]" class="fee-paid-on force-single-line">
                        Paid on
                        {{ feeDataComputed[monthIndex].createdAt | formatDate }}
                      </div>
                      <div v-if="feeDataComputed[monthIndex] !== false &&
                        !feeDataComputed[monthIndex] &&
                        !isBeforeJoin(selectedYear, monthIndex)
                        " class="fee-paid-on force-single-line">
                        <span v-if="member.membershipPlan.monthlyAmount !== 0">
                          ₹{{ feeToSettleUptoMonth(selectedYear, monthIndex) }}
                        </span>
                        <span v-else> Free </span>
                      </div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters v-else class="fee-months-parent">
                <v-col class="fee-month text-center px-1 pt-0 py-2" sm="3" xs="3" md="3" lg="2" cols="12"
                  v-for="(n, monthIndex) in 12" :key="monthIndex">
                  <v-card min-height="75" :class="['each-month-box d-flex align-center']" :loading="true">
                    <div style="width: 100%">
                      <div class="fee-month">{{ monthName(n) }}</div>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog v-model="deleteFeeDialog" width="500">
      <v-card tile>
        <v-toolbar color="warning" dark> Are you sure? </v-toolbar>

        <v-card-text class="py-2">
          This action will remove monthly fee saved for this month and also for the
          upcoming months.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text tile @click="deleteFeeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" tile text @click="deleteFee()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="payUptoFeeDialog" width="500">
      <v-card tile>
        <v-toolbar color="warning" dark> Are you sure? </v-toolbar>

        <v-card-text class="py-5" v-if="payUptoFeeDialog">
          Are you sure that this member paid ₹{{
            feeToSettleUptoMonth(selectedYear, payUptoFeeDate.getMonth())
          }}
          and settled monthly fees upto {{ monthName(payUptoFeeDate.getMonth()) }},
          {{ selectedYear }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text tile @click="payUptoFeeDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" tile text @click="payUpToFee()">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPlanWindow" width="700">
      <v-card tile>
        <v-toolbar color="primary" dark>
          <span v-if="member">Membership class of {{ member.name }}</span>
        </v-toolbar>

        <v-card-text class="pb-4 pt-7" v-if="showPlanWindow">
          <v-row no-gutters>
            <v-col cols="12" sm="6" class="px-2 pr-3 mt-3">
              <v-select @change="membershipChange" :loading="membershipPlansLoading" :disabled="membershipPlansLoading"
                label="Membership class" v-model="membershipPlan" :items="membershipPlans" item-text="name"
                item-value="_id" menu-props="auto" hide-details class="py-0 my-0">
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" class="px-2 pl-3 text-right">
              <v-checkbox v-model="planFeePaid" @change="isPaidChange"
                :label="`Membership fee ${planFeePaid ? 'paid.' : 'not paid.'}`"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text tile @click="showPlanWindow = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirmMembershipFee" width="600">
      <v-card tile>
        <v-toolbar color="warning" dark>
          <span v-if="member">Are you sure?</span>
        </v-toolbar>

        <v-card-text class="pb-4 pt-7" v-if="showConfirmMembershipFee">
          <span v-if="planFeePaid">
            Are you sure this member paid membership fee of ₹{{
              member.membershipPlan.amount
            }}, this will save as member payed membership fee today.
          </span>
          <span v-else>
            This action will save this member as not payed membership fee and
            the membership fee of ₹{{ member.membershipPlan.amount }} will be
            added to this members dues.
          </span>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text tile @click="confirmMembershipFeeChange">
            Yes
          </v-btn>
          <v-btn color="primary" text tile @click="cancelMembershipFeeChange">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useMemberFeeWindow } from "@/modules";

export default {
  setup: useMemberFeeWindow
};
</script>
