<template>
  <div style="height: 100%" class="single-member">
    <div
      class="single-member-parent"
      style="height: 100%"
      ref="scrollable"
      v-bar
    >
      <div>
        <v-container>
          <div v-if="!loading">
            <div v-if="!isEditMode && !newMember" class="py-5">
              <v-card tile class="pa-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="px-2">
                    <v-card-text class="pb-0 text-right">
                      <span
                        v-if="!memberData.active"
                        class="not-assigned red--text pt-1"
                        style="vertical-align: middle"
                        >Removed</span
                      >
                      <v-btn
                        @click="gotoNewMemberPage"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Add new member</v-btn
                      >
                      <v-btn
                        @click="showFeeWindow"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Fee and Membership</v-btn
                      >
                      <v-btn
                        @click="showPhotoWindow = true"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Photo and Signature</v-btn
                      >
                      <v-btn
                        @click="signPasscodeDialog = true"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Sign Passcode</v-btn
                      >
                      <v-btn
                        @click="isEditMode = true"
                        x-small
                        tile
                        class="primary ml-2 mb-2"
                        >Edit</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Member Number </v-card-text>
                    <v-card-title class="pt-0">
                      # {{ memberData.memberNumber }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Membership class </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.membershipPlan.name }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Name </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.name }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Gender </v-card-text>
                    <v-card-title class="pt-0">
                      {{ genderLabels[memberData.gender] }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Date of Join </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.dateOfJoin | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col cols="12" sm="6" class="px-2">
                    <v-card-text class="pb-0"> Address </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.address }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="memberData.dateOfBirth"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Date of Birth </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.dateOfBirth | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    v-if="memberData.dateOfBirth"
                    cols="12"
                    sm="6"
                    class="px-2"
                  >
                    <v-card-text class="pb-0"> Age </v-card-text>
                    <v-card-title class="pt-0">
                      {{ calculateAge(memberData.dateOfBirth) }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.phone && memberData.phone.length"
                  >
                    <v-card-text class="pb-0"> Phone </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.phone }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.phone2 && memberData.phone2.length"
                  >
                    <v-card-text class="pb-0"> Phone 2</v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.phone2 }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.gaurdian && memberData.gaurdian.length"
                  >
                    <v-card-text class="pb-0"> Gaurdian Name</v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.gaurdian }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.job && memberData.job.length"
                  >
                    <v-card-text class="pb-0"> Job </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.job }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.email && memberData.email.length"
                  >
                    <v-card-text class="pb-0"> Email</v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.email }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="memberData.bloodType && memberData.bloodType.length"
                  >
                    <v-card-text class="pb-0">Blood Type</v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.bloodType }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="!memberData.active && memberData.dateRemoved"
                  >
                    <v-card-text class="pb-0"> Date Removed </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.dateRemoved | formatDate }}
                    </v-card-title>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    class="px-2"
                    v-if="!memberData.active && memberData.removedReason"
                  >
                    <v-card-text class="pb-0"> Removed Reason </v-card-text>
                    <v-card-title class="pt-0">
                      {{ memberData.removedReason }}
                    </v-card-title>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div v-else class="py-5">
              <v-card tile class="pa-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="px-2">
                    <v-card-text class="pb-0 text-right">
                      <v-btn
                        :disabled="!hasChanged"
                        v-if="!newMember"
                        @click="updateData"
                        x-small
                        tile
                        color="primary"
                        class="ml-2 mb-2"
                        >Update</v-btn
                      >
                      <v-btn
                        v-else
                        @click="saveNewData"
                        x-small
                        tile
                        color="primary"
                        class="ml-2 mb-2"
                        >Save</v-btn
                      >
                      <v-btn
                        v-if="!newMember"
                        @click="isEditMode = false"
                        x-small
                        tile
                        class="ml-2 mb-2"
                        >Cancel</v-btn
                      >
                      <v-btn
                        v-else-if="isBackPossible"
                        @click="goBackWard"
                        x-small
                        tile
                        class="ml-2 mb-2"
                        >Cancel</v-btn
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-form
                  autocomplete="off"
                  ref="form"
                  v-model="formValid"
                  lazy-validation
                >
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Member Number </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          disabled
                          :loading="newMember && nextNumberLoading"
                          :value="
                            newMember
                              ? `# ${nextNumber}`
                              : `# ${memberData.memberNumber}`
                          "
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col v-if="!newMember" cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Membership class </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          disabled
                          :value="memberData.membershipPlan.name"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col v-else cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Membership class </v-card-text>
                      <v-card-title class="pt-0">
                        <v-select
                          :rules="[
                            (v) => (v && v.trim().length ? true : 'Required')
                          ]"
                          :loading="membershipPlansLoading"
                          v-model="membershipPlan"
                          :items="membershipPlans"
                          item-text="name"
                          item-value="_id"
                          menu-props="auto"
                          hide-details
                          class="py-0 my-0"
                        >
                        </v-select>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Name </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v.trim().length && v.trim().length <= 50
                                ? true
                                : 'Required (Max: 50 chars)'
                          ]"
                          class="py-0 my-0"
                          v-model="editModeMemberData.name"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Gender </v-card-text>
                      <v-card-title class="pt-0">
                        <v-select
                          :rules="[
                            (v) => (v && v.trim().length ? true : 'Required')
                          ]"
                          v-model="editModeMemberData.gender"
                          :items="genders"
                          item-text="label"
                          item-value="value"
                          menu-props="auto"
                          hide-details
                          class="py-0 my-0"
                        >
                        </v-select>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Date Added </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="dateOfJoinMenuRef"
                          v-model="dateOfJoinMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeMemberData.dateOfJoin"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :rules="[
                                (v) =>
                                  v && v.trim().length ? true : 'Required'
                              ]"
                              class="py-0 my-0"
                              :value="formatDate(editModeMemberData.dateOfJoin)"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editModeMemberData.dateOfJoin"
                            no-title
                            scrollable
                            to
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateOfJoinMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dateOfJoinMenuRef.save(
                                  editModeMemberData.dateOfJoin
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Address </v-card-text>
                      <v-card-title class="pt-0">
                        <v-textarea
                          rows="1"
                          :rules="[
                            (v) =>
                              v.trim().length && v.trim().length <= 300
                                ? true
                                : 'Required (Max: 300 chars)'
                          ]"
                          class="py-0 my-0"
                          v-model="editModeMemberData.address"
                        ></v-textarea>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Date of Birth </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="dateOfBirthMenuRef"
                          v-model="dateOfBirthMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeMemberData.dateOfBirth"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              class="py-0 my-0"
                              :value="
                                formatDate(editModeMemberData.dateOfBirth)
                              "
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              @click:clear="dobTextCleared"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :max="maxDob"
                            v-model="editModeMemberData.dateOfBirth"
                            no-title
                            scrollable
                            :reactive="true"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateOfBirthMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dateOfBirthMenuRef.save(
                                  editModeMemberData.dateOfBirth
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Age </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              v && v.toString().trim().length > 3
                                ? 'Enter a valid age'
                                : true
                          ]"
                          class="py-0 my-0"
                          v-model="editModeMemberData.age"
                          v-mask="'###'"
                          @keyup="ageKeyUp"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Phone </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          v-mask="'P############'"
                          dense
                          label=""
                          class="py-0 my-0 member-phone"
                          v-model="editModeMemberData.phone"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Phone 2 </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          v-mask="'P############'"
                          dense
                          label=""
                          class="py-0 my-0 member-phone"
                          v-model="editModeMemberData.phone2"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Gaurdian Name </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              !v || !v.trim().length || v.trim().length < 50
                                ? true
                                : 'Required (Max: 50 chars)'
                          ]"
                          dense
                          label=""
                          class="py-0 my-0"
                          v-model="editModeMemberData.gaurdian"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Job </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          :rules="[
                            (v) =>
                              !v || !v.trim().length || v.trim().length < 60
                                ? true
                                : 'Required (Max: 60 chars)'
                          ]"
                          dense
                          label=""
                          class="py-0 my-0"
                          v-model="editModeMemberData.job"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Email </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          dense
                          :rules="[
                            (v) =>
                              (v &&
                                v.trim().length < 200 &&
                                validateEmail(v)) ||
                              !v ||
                              !v.trim().length
                                ? true
                                : 'Invalid email'
                          ]"
                          class="py-0 my-0"
                          v-model="editModeMemberData.email"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Blood Type </v-card-text>
                      <v-card-title class="pt-0">
                        <v-select
                          v-model="editModeMemberData.bloodType"
                          :items="bloodTypes"
                          dense
                          clearable
                        ></v-select>
                      </v-card-title>
                    </v-col>
                    <v-col cols="12" sm="6" class="px-2">
                      <v-card-text class="pb-0"> Active </v-card-text>
                      <v-card-title class="pt-2">
                        <v-btn-toggle
                          v-model="editModeMemberData.active"
                          mandatory
                          tile
                        >
                          <v-btn
                            small
                            :color="
                              !editModeMemberData.active ? 'error' : 'normal'
                            "
                          >
                          </v-btn>
                          <v-btn
                            small
                            :color="
                              editModeMemberData.active ? 'success' : 'normal'
                            "
                          >
                            <v-icon>mdi-check-bold</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                      </v-card-title>
                    </v-col>
                    <v-col
                      v-if="!editModeMemberData.active"
                      cols="12"
                      sm="6"
                      class="px-2"
                    >
                      <v-card-text class="pb-0"> Date Removed </v-card-text>
                      <v-card-title class="pt-0">
                        <v-menu
                          ref="dateRemovedMenuRef"
                          v-model="dateRemovedMenu"
                          :close-on-content-click="false"
                          :return-value.sync="editModeMemberData.dateRemoved"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="py-0 my-0"
                              :value="
                                formatDate(editModeMemberData.dateRemoved)
                              "
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editModeMemberData.dateRemoved"
                            no-title
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateRemovedMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dateRemovedMenuRef.save(null)"
                            >
                              Clear
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="
                                $refs.dateRemovedMenuRef.save(
                                  editModeMemberData.dateRemoved
                                )
                              "
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-card-title>
                    </v-col>
                    <v-col
                      v-if="!editModeMemberData.active"
                      cols="12"
                      sm="6"
                      class="px-2"
                    >
                      <v-card-text class="pb-0"> Removed Reason </v-card-text>
                      <v-card-title class="pt-0">
                        <v-text-field
                          class="py-0 my-0"
                          :rules="[
                            (v) =>
                              v && v.length && v.length > 60
                                ? 'Max: 60 chars'
                                : true
                          ]"
                          v-model="editModeMemberData.removedReason"
                        ></v-text-field>
                      </v-card-title>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </div>
          </div>
          <div class="py-5" v-else>
            <SingleBookSkeltonLoader />
          </div>
        </v-container>
      </div>
    </div>
    <MemberFeeWindow />
    <MemberPhotoAndSignature v-model="showPhotoWindow" :member="memberData" />
    <v-dialog v-model="signPasscodeDialog" width="500">
      <v-card>
        <v-toolbar dark>
          <span>Save Sign Passcode for this member</span>
        </v-toolbar>

        <v-card-text class="pt-10">
          <v-text-field
            v-model="signPasscodeField"
            type="password"
            v-mask="'####'"
            class="mx-2"
            label="New Passcode"
          />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            tile
            text
            :disabled="signPasscodeField.length < 4"
            @click="updateSignPasscode"
          >
            Save
          </v-btn>
          <v-btn text @click="signPasscodeDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { useSingleMember } from "@/modules";
import SingleBookSkeltonLoader from "@/components/SingleBookSkeltonLoader";
import MemberFeeWindow from "@/components/MemberFeeWindow";
import MemberPhotoAndSignature from "@/components/MemberPhotoAndSignature";

export default {
  setup: useSingleMember,
  components: {
    SingleBookSkeltonLoader,
    MemberFeeWindow,
    MemberPhotoAndSignature
  }
};
</script>
