<template>
  <v-dialog width="700" :value="value" @input="$emit('input', $event)">
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar dark class="primary">
          Photo and signature of {{ member.name }}</v-toolbar
        >
        <v-row
          class="mx-0 px-4 py-0"
          style="max-height: 400px; overflow-y: auto"
        >
          <v-col
            cols="12"
            sm="12"
            xl="6"
            lg="6"
            md="6"
            class="text-center"
            style="overflow-x: hidden"
          >
            <v-card-text>Photo</v-card-text>
            <div class="member-img-wrapper">
              <img v-if="member.hasPhoto" height="200" :src="member.photo" />
              <v-icon size="200" v-else>mdi-account</v-icon>
            </div>
            <div class="member-img-changer mt-3">
              <v-file-input
                @change="photoAdded"
                accept="image/*"
                label="File input"
                ref="photoFileInput"
                class="d-none"
                clearable
              ></v-file-input>
              <v-btn @click="photoSelect" tile x-small class="primary mx-1">{{
                member.hasPhoto ? "Change Photo" : "Add Photo"
              }}</v-btn>
              <v-btn
                v-if="member.hasPhoto"
                @click="deletePhoto"
                tile
                x-small
                class="primary mx-1"
                >Delete Photo</v-btn
              >
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            xl="6"
            lg="6"
            md="6"
            class="text-center"
            style="overflow-x: hidden"
          >
            <v-card-text>Signature</v-card-text>
            <div class="member-img-wrapper">
              <img
                v-if="member.hasSignature"
                width="150"
                :src="member.signature"
              />
              <v-icon size="150" v-else>mdi-image</v-icon>
            </div>
            <div class="member-img-changer mt-3">
              <v-file-input
                @change="signatureAdded"
                accept="image/*"
                label="File input"
                ref="signatureFileInput"
                class="d-none"
                clearable
              ></v-file-input>
              <v-btn
                @click="signatureSelect"
                tile
                x-small
                class="primary mx-1"
                >{{
                  member.hasSignature ? "Change Signature" : "Add Signature"
                }}</v-btn
              >
              <v-btn
                v-if="member.hasSignature"
                @click="deleteSignature"
                tile
                x-small
                class="primary mx-1"
                >Delete Signature</v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-card-actions class="justify-end">
          <v-btn text @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
      <CropperDialog
        v-model="cropperDialogShown"
        :src="cropperSrc"
        :onSubmit="onSubmit"
        :aspectRatio="aspectRatio"
        submitText="Save"
      />
    </template>
  </v-dialog>
</template>

<script>
import { reactive, ref, toRefs, watch } from "@vue/composition-api";
import CropperDialog from "./CropperDialog";
import helpers from "@/helpers";
import axios from "axios";
import store from "@/store";

const state = reactive({
  cropperDialogShown: false,
  cropperSrc: "",
  onSubmit: () => {},
  aspectRatio: 1
});

const saveMemberImage = (imageData, id, type) => {
  const hasImage = imageData !== "";
  store.app.showLoader(`${hasImage ? "Updating" : "Deleting"} member ${type}`);
  axios
    .patch(`${process.env.VUE_APP_API}/member/${id}/${type}`, {
      imageData
    })
    .then((response) => {
      response.data.url &&
        (store.singleMember.state.memberData[type] = response.data.url);
      store.singleMember.state.memberData[
        "has" + helpers.string.pascalCase(type)
      ] = hasImage;
      store.app.showAlert(`${hasImage ? "Updated" : "Deleted"} member ${type}`);
      state.cropperDialogShown = false;
      store.members.state.loadedAll = false;
      store.members.state.data = [];
    })
    .catch(() => {
      store.app.showAlert("Some error occured!");
    })
    .finally(() => store.app.hideLoader());
};

export default {
  props: {
    value: {
      type: Boolean
    },
    member: {
      type: Object
    }
  },
  components: {
    CropperDialog
  },
  setup: (props) => {
    const photoFileInput = ref(null);
    const signatureFileInput = ref(null);
    return {
      ...toRefs(state),
      photoFileInput,
      signatureFileInput,
      photoSelect: () => {
        photoFileInput.value.$el.querySelector("input").click();
      },
      signatureSelect: () => {
        signatureFileInput.value.$el.querySelector("input").click();
      },
      deletePhoto: () => saveMemberImage("", props.member._id, "photo"),
      deleteSignature: () => saveMemberImage("", props.member._id, "signature"),
      photoAdded: (file) => {
        photoFileInput.value.$el.querySelector("input").value = null;
        helpers.getBase64FromFile(file, (data) => {
          state.cropperDialogShown = true;
          state.cropperSrc = data;
          state.aspectRatio = 35 / 45;
          state.onSubmit = (imageData) =>
            saveMemberImage(imageData, props.member._id, "photo");
        });
      },
      signatureAdded: (file) => {
        signatureFileInput.value.$el.querySelector("input").value = null;
        helpers.getBase64FromFile(file, (data) => {
          state.cropperDialogShown = true;
          state.cropperSrc = data;
          state.aspectRatio = 2 / 1;
          state.onSubmit = (imageData) =>
            saveMemberImage(imageData, props.member._id, "signature");
        });
      }
    };
  }
};
</script>
